import Router from 'next/router';
import notificationConfig from './notificationConfig';

export const pushNotification = (item) => {
  const pushURL = `${notificationConfig[item.event_type]?.getLink(item)}`;
  switch (item.event_type) {
    case 'member_renew_notification':
      Router.push(
        {
          pathname: pushURL,
        },
        undefined
      );
      break;
    case 'member_expired_notification':
      Router.push(
        {
          pathname: pushURL,
        },
        undefined
      );
      break;
    case 'notification_new_review_course':
      if (item.object_slug !== undefined) {
        Router.push(
          {
            pathname: pushURL,
            query: { tab: '6', object: item?.object_id },
          },
          undefined
        );
      }
      break;
    case 'course_enroll':
      Router.push(
        {
          pathname: pushURL,
          query: { tab: 'info' },
        },
        undefined
      );
      break;
    case 'contact_enquiry':
      Router.push(
        {
          pathname: pushURL,
          query: { profile: item?.object_id },
        },
        undefined
      );
      break;
    case 'notify_instructor_on_question_add_course':
      Router.push(
        {
          pathname: pushURL,
          query: { tab: '4', doubt: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_announcement_post':
      Router.push(
        {
          pathname: pushURL,
          query: { tab: 'announcement', announcement: item?.object_id },
        },
        undefined
      );
      break;
    case 'room_discussion_post_create':
      Router.push(
        {
          pathname: pushURL,
          query: { discussion: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_like_room_post':
      Router.push(
        {
          pathname: pushURL,
          query: { discussion: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_like_room_comment':
      Router.push(
        {
          pathname: pushURL,
          query: { comment: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_accept_invite':
      Router.push(
        {
          pathname: pushURL,
          query: { tab: 'members', member: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_reject_invite':
      Router.push(
        {
          pathname: pushURL,
          query: { tab: 'members', subtab: 2, member: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_comment_course_post':
      Router.push(
        {
          pathname: pushURL,
          query: { comment: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_comment_room_post':
      Router.push(
        {
          pathname: pushURL,
          query: { discussion: item?.oid },
        },
        undefined
      );
      break;
    case 'notification_like_course_comment':
      Router.push(
        {
          pathname: pushURL,
          query: { comment: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_react_room_post':
      Router.push(
        {
          pathname: pushURL,
          query: { discussion: item?.postId },
        },
        undefined
      );
      break;
    case 'notification_react_room_comment':
      Router.push(
        {
          pathname: pushURL,
          query: { discussion: item?.oid },
        },
        undefined
      );
      break;
    case 'notification_comment_reply':
      Router.push(
        {
          pathname: pushURL,
          query: { discussion: item?.postId },
        },
        undefined
      );
      break;
    case 'notification_assesment_post':
      Router.push(
        {
          pathname: pushURL,
          query: { tab: 'assessment' },
        },
        undefined
      );
      break;

    case 'notification_assessment_submission':
      Router.push(
        {
          pathname: pushURL,
          // query: { answer: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_assesment_feedback_question':
      Router.push(
        {
          pathname: pushURL,
          // query: { qcomment: item?.object_id },
        },
        undefined
      );
      break;
    case 'notification_assesment_feedback_answer':
      Router.push(
        {
          pathname: pushURL,
          // query: { acomment: item?.object_id },
        },
        undefined
      );
      break;
    case 'doubt_report':
      Router.push(
        {
          pathname: pushURL,
          query: { order_by_id: item?.object_id },
        },
        undefined
      );
      break;
    case 'doubt_report_rejected_by_admin':
      Router.push(
        {
          pathname: pushURL,
        },
        undefined
      );
      break;
    case 'task_assgined':
      Router.push(
        {
          pathname: pushURL,
          query: { taskId: item?.object_id },
        },
        undefined
      );
      break;
    case 'task_due':
      Router.push(
        {
          pathname: pushURL,
          query: { taskId: item?.object_id },
        },
        undefined
      );
      break;
    case 'college_ambassador_form_submit':
      Router.push(
        {
          pathname: pushURL,
          query: { requestId: item?.object_id },
        },
        undefined
      );
      break;
    default:
      Router.push({
        pathname: pushURL,
      });
  }
};
