import * as actions from './index';
export const getNotifications = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: actions.GET_NOTIFICATIONS,
    payload: {
      url: `notifications/?${query}`,
      onSuccess: actions.GET_NOTIFICATIONS_SUCCEEDED,
      onFailure: actions.GET_NOTIFICATIONS_FAILED,
    },
  };
};

export const getNotificationsByPage = (page = {}) => {
  return {
    type: actions.GET_NOTIFICATIONS_BY_PAGE,
    payload: {
      url: `notifications/?page=${page}`,
      onSuccess: actions.GET_NOTIFICATIONS_BY_PAGE_SUCCEEDED,
      onFailure: actions.GET_NOTIFICATIONS_BY_PAGE_FAILED,
    },
  };
};

export const readAllNotifications = () => {
  return {
    type: actions.READ_ALL_NOTIFICATIONS,
    payload: {
      url: `read-all-notification/`,
      onSuccess: actions.READ_ALL_NOTIFICATIONS_SUCCEEDED,
      onFailure: actions.READ_ALL_NOTIFICATIONS_FAILED,
    },
  };
};

export const readSingleNotification = (slug = {}, tag) => {
  return {
    type: actions.READ_SINGLE_NOTIFICATION,
    payload: {
      url: `read-notification/${slug}/${tag}/`,
      onSuccess: actions.READ_SINGLE_NOTIFICATION_SUCCEEDED,
      onFailure: actions.READ_SINGLE_NOTIFICATION_FAILED,
    },
  };
};

export const sendNotificationForReview = () => {
  return {
    type: actions.SEND_NOTIFICATION_FOR_TUTOR_REVIEW,
    payload: {
      url: `send-notification/`,
      onSuccess: actions.SEND_NOTIFICATION_FOR_TUTOR_REVIEW_SUCCEEDED,
      onFailure: actions.SEND_NOTIFICATION_FOR_TUTOR_REVIEW_FAILED,
    },
  };
};

export const clearNotificationState = () => {
  return {
    type: actions.CLEAR_NOTIFICATION_STATE,
  };
};

export const setLiveNotificationMessage = (payload) => {
  return {
    type: actions.SET_LIVE_NOTIFICATION_MESSAGE,
    payload: payload?.data,
  };
};

export const removeLiveNotificationMessage = (payload) => {
  return {
    type: actions.REMOVE_LIVE_NOTIFICATION_MESSAGE,
    payload: payload?.data,
  };
};
