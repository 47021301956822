const notificationConfig = {
  new_user_enroll_notification: {
    getLink: (data) => `/course/${data?.object_slug}`,
  },
  comment_report: {
    getLink: () => `/`,
  },
  contact_enquiry: {
    getLink: () => `/dashboard/lead`,
  },
  member_renew_notification: {
    getLink: (data) => `/course/${data?.object_slug}`,
  },
  member_expired_notification: {
    getLink: (data) => `/course/${data?.object_slug}`,
  },
  course_enroll: {
    getLink: (data) => `/dashboard/courses/course-list/${data?.object_slug}`,
  },
  course_review: {
    getLink: (data) => `/dashboard/courses/course-list/${data?.object_slug}?tab=6`,
  },
  doubt_report: {
    getLink: (data) => `/dashboard/doubts/reports`,
  },
  doubt_report_rejected_by_admin: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  email: {
    getLink: () => `/`,
  },
  member_add_room: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  notification_comment_course_post: {
    getLink: (data) => `/doubts/${data?.oid}`,
  },
  notification_like_course_post: {
    getLink: (data) => `/doubts/${data?.oid}`,
  },
  notification_like_room_post: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  notification_like_room_comment: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  notification_comment_room_post: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  notification_react_room_post: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  notification_react_room_comment: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  notification_comment_reply: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  notification_like_course_comment: {
    getLink: (data) => `/course/lesson/${data?.object_slug}`,
  },
  notification_new_instructor_signup: {
    getLink: (data) => `/dashboard/users/instructor/${data?.object_id}`,
  },
  notification_new_review_course: {
    getLink: (data) => `/dashboard/courses/course-list/${data?.object_slug}`,
  },
  notify_instructor_on_question_add_course: {
    getLink: (data) => `/dashboard/courses/course-list/${data?.object_slug}`,
  },
  requestcourse: {
    getLink: () => `/dashboard/courses/course-request`,
  },
  room_discussion_post_create: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  room_invite_user: {
    getLink: (data) => `/room/${data?.object_slug}`,
  },
  tutor_review: {
    getLink: (data) => {
      return `/dashboard/users/instructor/${data?.object_id}`;
    },
  },
  tutor_approve: {
    getLink: (notification) => {
      return `/profile`;
    },
  },
  tutor_reject: {
    getLink: (notification) => {
      return `/profile`;
    },
  },
  user: {
    getLink: (data) => {
      return `/dashboard/users/${data.user}`;
    },
  },
  notify_user_on_instructor_reply_to_question: {
    getLink: (data) => {
      return `/posts/${data?.object_id}`;
    },
  },
  notification_announcement_post: {
    getLink: (data) => {
      return `/room/${data?.object_slug}`;
    },
  },
  notification_accept_invite: {
    getLink: (data) => {
      return `/room/${data?.object_slug}`;
    },
  },
  notification_reject_invite: {
    getLink: (data) => {
      return `/room/${data?.object_slug}`;
    },
  },
  // assessment
  notification_assesment_post: {
    getLink: (data) => {
      // return `/room/${data?.object_slug}/assessment/${data?.object_id}`;
      return `/room/${data?.object_slug}`;
    },
  },
  notification_assessment_submission: {
    getLink: (data) => {
      return `/room/${data?.object_slug}/assessment/${data?.assessment_id}/dashboard`;
    },
  },
  notification_assesment_feedback: {
    getLink: (data) => {
      return `/room/${data?.object_slug}/assessment/${data?.assessment_id}/dashboard`;
    },
  },
  notification_assesment_feedback_question: {
    getLink: (data) => {
      return `/room/${data?.object_slug}/assessment/${data?.assessment_id}`;
    },
  },
  notification_assesment_feedback_answer: {
    getLink: (data) => {
      return `/room/${data?.object_slug}/assessment/${data?.assessment_id}`;
    },
  },
  //task
  task_assgined: {
    getLink: () => {
      return `/dashboard/task`;
    },
  },
  task_due: {
    getLink: () => {
      return `/dashboard/task`;
    },
  },
  college_ambassador_form_submit: {
    getLink: () => {
      return `/dashboard/college/ambassador-request`;
    },
  },
};

export default notificationConfig;
