import React, { memo, useEffect, useMemo, useState } from 'react';
import useWidth from '@/helpers/widthUtil';
import { notification as antNotification, List, Spin } from 'antd';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { notificationObjModify } from 'src/reducers/notifications';
import {
  readAllNotifications,
  readSingleNotification,
  removeLiveNotificationMessage,
  setLiveNotificationMessage,
} from '../../../actions/notifications';
import { isLogin } from '../../../helpers/loginHelper';
import { pushNotification } from './notificationHelper';

const Notification = (props) => {
  const { isMobile } = useWidth();
  const dispatch = useDispatch();

  const notificaitionSocketRef: any = React.useRef();
  const notification = useSelector(
    (state: any) => state?.notificationReducer?.notification
  );
  const unreadCount = notification?.unreadCount;
  const loading = notification?.loading;
  const [parsedNotification, setParsedNotification] = useState(null);

  useEffect(() => {
    return () => {
      setParsedNotification(null);
    };
  }, []);

  // Using websocket
  useMemo(() => {
    if (isLogin()) {
      var wsStart = 'ws://';
      if (location.protocol == 'https:') {
        wsStart = 'wss://';
      }
      var endpoint =
        process.env.NEXT_PUBLIC_SOCKET_URL +
        `/notification/?key=${window.localStorage
          ?.getItem('token')
          ?.split('Token')[1]
          ?.trim()}`;
      notificaitionSocketRef.current = new ReconnectingWebSocket(endpoint);
      notificaitionSocketRef.current.onopen = (e) => {};
      notificaitionSocketRef.current.onmessage = (e) => {
        setParsedNotification(JSON.parse(e.data));
        // audioPlay();
      };
      notificaitionSocketRef.current.onerror = (e) => {};
      notificaitionSocketRef.current.onclose = (e) => {};
      return () => {
        if (notificaitionSocketRef.current) {
          notificaitionSocketRef.current.close();
        }
      };
    }
  }, []);

  useEffect(() => {
    if (parsedNotification !== null) {
      switch (parsedNotification?.type) {
        case 'send_notification':
          dispatch(setLiveNotificationMessage(parsedNotification));
          var liveNotification = notificationObjModify(parsedNotification?.data);
          antNotification.open({
            message: (
              <div
                className="cursor-pointer live-notification p-3 d-flex align-items-center"
                onClick={(e) => {
                  markAsRead(e, liveNotification);
                  antNotification.destroy();
                }}
              >
                {liveNotification?.avatar}
                <div className="ant-list-item-meta-content ml-3">
                  <h4 className="ant-list-item-meta-title">
                    <div className="d-flex">
                      <a
                        className="notification-title"
                        dangerouslySetInnerHTML={{
                          __html: liveNotification?.title,
                        }}
                      />
                    </div>
                  </h4>
                  <div className="ant-list-item-meta-description">
                    <small>{liveNotification?.time}</small>
                  </div>
                </div>
              </div>
            ),
            placement: 'bottomLeft',
            duration: 5,
            closeIcon: <></>,
            className: 'websocket-notification',
          }) as any;
          break;
        case 'delete_notification':
          dispatch(removeLiveNotificationMessage(parsedNotification));
          break;
      }
    }
  }, [parsedNotification]);

  const handleReadAll = () => {
    dispatch(readAllNotifications());
  };

  const markAsRead = (e, item) => {
    var tag = item?.readAt === null ? 'read' : 'unread';
    if (item?.readAt === null) {
      dispatch(readSingleNotification(item?.slug, tag));
    }

    pushNotification(item);
  };
  return (
    <div>
      <Spin spinning={loading} className="notificationContainer border-14">
        <ul className={isMobile ? 'mobile-notification' : ''}>
          {
            // !loading &&
            <li className="dropdown-menu-header p-2">
              <div className="dropdown-header mt-0">
                <h6 className="text-white">
                  {unreadCount > 0
                    ? `${unreadCount} New Notifications`
                    : 'No New Notifications'}
                </h6>
              </div>
            </li>
          }
          <li>
            <List
              className="header-notifications"
              itemLayout="horizontal"
              dataSource={notification?.data?.data}
              footer={
                <>
                  <div>
                    <Link href="/notifications">
                      <a
                        className="read-all-notification"
                        style={{ fontSize: '12px' }}
                      >
                        View all notifications
                      </a>
                    </Link>
                    <a
                      className="float-right read-all-notification"
                      onClick={() => handleReadAll()}
                      style={{ fontSize: '12px' }}
                    >
                      Mark all as read
                    </a>
                  </div>
                </>
              }
              renderItem={(item: any) => (
                <div className="notifications">
                  <List.Item
                    style={{
                      background: item?.readAt === null ? '#f0f8ff' : 'transparent',
                      borderColor: '#dae1e7',
                    }}
                    className="border-bottom"
                  >
                    <List.Item.Meta
                      className="mb-0"
                      key={item.id}
                      avatar={item.avatar}
                      title={
                        <div className="d-flex flex-row justify-content-between">
                          <div
                            className="d-flex flex-column notification-content mr-3"
                            onClick={(e) => markAsRead(e, item)}
                          >
                            <div className="d-flex flex-row justify-content-between">
                              <a
                                className="notification-title"
                                dangerouslySetInnerHTML={{ __html: item.title }}
                              ></a>
                            </div>
                            <div className="d-flex">
                              <small className="notification-time mr-1">
                                {item.time}
                              </small>
                              {item?.readAt === null ? (
                                <img
                                  alt={'Tick read'}
                                  style={{ height: '14px' }}
                                  src={'/images/unread.svg'}
                                  title="Unread"
                                />
                              ) : (
                                <img
                                  className="mt-1"
                                  alt={'Tick read'}
                                  style={{ height: '8px' }}
                                  src={'/images/tick.svg'}
                                  title="Read"
                                />
                              )}
                            </div>
                          </div>
                          <input
                            type="checkbox"
                            className="my-auto ml-auto float-right"
                            checked={item?.readAt !== null}
                            onChange={(e) =>
                              dispatch(
                                readSingleNotification(
                                  item?.slug,
                                  item?.readAt === null ? 'read' : 'unread'
                                )
                              )
                            }
                            key={item.slug}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      }
                    />
                  </List.Item>
                </div>
              )}
            />
          </li>
        </ul>
      </Spin>
    </div>
  );
};

export default memo(Notification);
