import * as actions from './types';

export const userLogin = (data = {}) => {
  return {
    type: actions.USER_LOGIN,
    payload: {
      data,
      url: 'login/',
      onSuccess: actions.USER_LOGIN_SUCCEEDED,
      onFailure: actions.USER_LOGIN_FAILED,
    },
  };
};

export const verifyNewDevice = (data = {}) => {
  return {
    type: actions.NEW_DEVICE_VERIFICATION_REQUEST,
    payload: {
      data,
      url: 'verify-new-device/',
      onSuccess: actions.NEW_DEVICE_VERIFICATION_SUCCEEDED,
      onFailure: actions.NEW_DEVICE_VERIFICATION_FAILED,
    },
  };
};

export const resendVerificationOtp = (data = {}) => {
  return {
    type: actions.RESEND_VERIFICATION_DEVICE_REQUEST,
    payload: {
      data,
      url: 'resend-verification-email/',
      onSuccess: actions.RESEND_VERIFICATION_DEVICE_SUCCEEDED,
      onFailure: actions.RESEND_VERIFICATION_DEVICE_FAILED,
    },
  };
};

export const userRegister = (data = {}) => {
  return {
    type: actions.USER_REGISTER,
    payload: {
      data,
      url: 'signup/',
      onSuccess: actions.USER_REGISTER_SUCCEEDED,
      onFailure: actions.USER_REGISTER_FAILED,
    },
  };
};

export const getProfile = () => {
  return {
    type: actions.GET_PROFILE,
    payload: {
      url: 'my-profile/',
      onSuccess: actions.GET_PROFILE_SUCCEEDED,
      onFailure: actions.GET_PROFILE_FAILED,
    },
  };
};

export const updateProfile = (data = {}) => {
  return {
    type: actions.UPDATE_PROFILE,
    payload: {
      data,
      url: 'my-profile/',
      onSuccess: actions.UPDATE_PROFILE_SUCCEEDED,
      onFailure: actions.UPDATE_PROFILE_FAILED,
    },
  };
};

export const logoutUser = () => {
  return {
    type: actions.USER_LOGOUT,
    payload: {
      onSuccess: actions.USER_LOGOUT_SUCCEEDED,
    },
  };
};

export const userVerification = (data = {}) => {
  return {
    type: actions.USER_VERIFICATION,
    payload: {
      data,
      url: 'validate-token/',
      onSuccess: actions.USER_VERIFICATION_SUCCEEDED,
      onFailure: actions.USER_VERIFICATION_FAILED,
    },
  };
};

export const userVerificationRequest = (data = {}) => {
  return {
    type: actions.USER_VERIFICATION_REQUEST,
    payload: {
      data,
      url: 'request-token/',
      onSuccess: actions.USER_VERIFICATION_REQUEST_SUCCEEDED,
      onFailure: actions.USER_VERIFICATION_REQUEST_FAILED,
    },
  };
};

export const changePassword = (data = {}) => {
  return {
    type: actions.CHANGE_USER_PASSWORD,
    payload: {
      data,
      url: 'change-password/',
      onSuccess: actions.CHANGE_USER_PASSWORD_SUCCEEDED,
      onFailure: actions.CHANGE_USER_PASSWORD_FAILED,
    },
  };
};

// Forgot password
export const requestToken = (data = {}, is_password = 'True') => {
  return {
    type: actions.REQUEST_TOKEN,
    payload: {
      data,
      url: `request-token/?is_password=${is_password}`,
      onSuccess: actions.REQUEST_TOKEN_SUCCEEDED,
      onFailure: actions.REQUEST_TOKEN_FAILED,
    },
  };
};

export const tokenValidation = (data = {}, is_password = 'True') => {
  return {
    type: actions.TOKEN_VALIDATION,
    payload: {
      data,
      url: `validate-token/?is_password=${is_password}`,
      onSuccess: actions.TOKEN_VALIDATION_SUCCEEDED,
      onFailure: actions.TOKEN_VALIDATION_FAILED,
    },
  };
};

export const resetPassword = (data = {}) => {
  return {
    type: actions.RESET_PASSWORD,
    payload: {
      data,
      url: `rest-password-confirm/`,
      onSuccess: actions.RESET_PASSWORD_SUCCEEDED,
      onFailure: actions.RESET_PASSWORD_FAILED,
    },
  };
};

export const setData = (data = {}) => {
  return {
    type: actions.SET_DATA,
    payload: {
      data,
    },
  };
};

export const setAuthNested = (data = {}) => {
  return {
    type: actions.SET_AUTH_NESTED_DATA,
    payload: {
      data,
    },
  };
};

export const addData = (data = {}) => {
  return {
    type: actions.ADD_DATA,
    payload: {
      data,
    },
  };
};

// Tutor Job

export const addJobExperience = (data = {}) => {
  return {
    type: actions.ADD_JOB_EXPERIENCE,
    payload: {
      data,
      url: 'tutor/job-experience/',
      onSuccess: actions.ADD_JOB_EXPERIENCE_SUCCEEDED,
      onFailure: actions.ADD_JOB_EXPERIENCE_FAILED,
    },
  };
};

export const editJobExperience = (data = {}, id = {}) => {
  return {
    type: actions.EDIT_JOB_EXPERIENCE,
    payload: {
      data,
      url: `tutor/job-experience/${id}/`,
      onSuccess: actions.EDIT_JOB_EXPERIENCE_SUCCEEDED,
      onFailure: actions.EDIT_JOB_EXPERIENCE_FAILED,
    },
  };
};

export const deleteJobExperience = (id = {}) => {
  return {
    type: actions.DELETE_JOB_EXPERIENCE,
    payload: {
      url: `tutor/job-experience/${id}/`,
      onSuccess: actions.DELETE_JOB_EXPERIENCE_SUCCEEDED,
      onFailure: actions.DELETE_JOB_EXPERIENCE_FAILED,
    },
  };
};

// Tutor Education

export const addEducation = (data = {}) => {
  return {
    type: actions.ADD_EDUCATION,
    payload: {
      data,
      url: 'tutor/education/',
      onSuccess: actions.ADD_EDUCATION_SUCCEEDED,
      onFailure: actions.ADD_EDUCATION_FAILED,
    },
  };
};

export const editEducation = (data = {}, id = {}) => {
  return {
    type: actions.EDIT_EDUCATION,
    payload: {
      data,
      url: `tutor/education/${id}/`,
      onSuccess: actions.EDIT_EDUCATION_SUCCEEDED,
      onFailure: actions.EDIT_EDUCATION_FAILED,
    },
  };
};

export const deleteEducation = (id = {}) => {
  return {
    type: actions.DELETE_EDUCATION,
    payload: {
      url: `tutor/education/${id}/`,
      onSuccess: actions.DELETE_EDUCATION_SUCCEEDED,
      onFailure: actions.DELETE_EDUCATION_FAILED,
    },
  };
};

export const getUserDashboardData = () => {
  return {
    type: actions.GET_USER_DASHBOARD,
    payload: {
      url: 'user-dashboard/',
      onSuccess: actions.GET_USER_DASHBOARD_SUCCEEDED,
      onFailure: actions.GET_USER_DASHBOARD_FAILED,
    },
  };
};

export const clearauthData = () => {
  return {
    type: actions.CLEAR_AUTH_DATA,
  };
};

//For unauthorized partner detail
export const getUnauthorizedPartnerDetail = (slug: string) => {
  return {
    type: actions.GET_SIGNUP_PARTNER_DETAIL_REQUEST,
    payload: {
      url: `partner/detail/${slug}`,
      onSuccess: actions.GET_SIGNUP_PARTNER_DETAIL_SUCCEEDED,
      onFailure: actions.GET_SIGNUP_PARTNER_DETAIL_FAILED,
    },
  };
};

//Register new partner
export const partnerSignup = (data = {}) => {
  return {
    type: actions.USER_LOGIN,
    payload: {
      data,
      url: 'partner-signup/',
      onSuccess: actions.USER_LOGIN_SUCCEEDED,
      onFailure: actions.USER_LOGIN_FAILED,
    },
  };
};

//get Address(country, city, country-code) from ip
export const getCountryCityCode = () => {
  return {
    type: actions.GET_ADDRESS_FROM_IPADDRESS_REQUEST,
    payload: {
      url: 'ip-address/',
      onSuccess: actions.GET_ADDRESS_FROM_IPADDRESS_SUCCEEDED,
      onFailure: actions.GET_ADDRESS_FROM_IPADDRESS_FAILED,
    },
  };
};

//signup otp
export const requestSignupOtp = (data = {}) => {
  return {
    type: actions.GET_SIGNUP_OTP,
    payload: {
      data,
      url: 'signup-otp/',
      onSuccess: actions.GET_SIGNUP_OTP_SUCCEEDED,
      onFailure: actions.GET_SIGNUP_OTP_FAILED,
    },
  };
};

export const verifySignupOtp = (data = {}) => {
  return {
    type: actions.VERIFY_SIGNUP_OTP,
    payload: {
      data,
      url: 'validate-sms-otp/',
      onSuccess: actions.VERIFY_SIGNUP_OTP_SUCCEEDED,
      onFailure: actions.VERIFY_SIGNUP_OTP_FAILED,
    },
  };
};